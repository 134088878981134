.container {
  max-width: 75rem;
  margin: 0px auto;
  padding: 0 2rem;
}
.container::after {
  display: block;
  clear: both;
  content: "";
}
@media (max-width: 36em) {
  .container {
    padding: 0 1rem;
  }
}

.icon {
  fill: currentColor;
  height: 1em;
  vertical-align: middle;
}
.icon:not(.icon--no-position) {
  position: relative;
  top: -0.075em;
}

.button {
  display: inline-block;
  border: 0.125rem solid currentColor;
  border-radius: 10000rem;
  color: whitesmoke;
  text-decoration: none;
  padding: 1rem 6rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.button:hover {
  color: #111111;
}

.button--form {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  color: black;
  background-color: #f0f0f0;
  border: 0.125rem solid #f0f0f0;
  font-weight: normal;
  text-transform: none;
}
.button--form:hover {
  color: dimgrey;
}

.button--background-blur {
  -webkit-backdrop-filter: blur(3rem);
  backdrop-filter: blur(3rem);
}

.button--white {
  color: white;
  text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
}
.button--white:hover {
  color: #e6e6e6;
}

.button--secondary {
  color: #f0f0f0;
  background-color: #1111;
  border: 0.125rem solid #f0f0f0;
}
.button--secondary:hover {
  color: #111111;
  background-color: #f0f0f0;
  border: 0.125rem solid #111111;
}
.iframe {
  width: 100%;
  height: 100%;
}

.button--slim {
  padding-left: 2rem;
  padding-right: 2rem;
}

.form__horizontal {
  display: flex;
  flex-wrap: wrap;
}

.form__group {
  position: relative;
  margin-bottom: 1.5rem;
}
.form__horizontal > .form__group {
  margin-right: 1.5rem;
  margin-bottom: 1rem;
}

.form__input {
  background-color: transparent;
  border: 0.125rem solid #f0f0f0;
  padding: 0.75rem 1rem;
  color: #f0f0f0;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  border-radius: 10000px;
  font-size: 1rem;
  width: 12.5rem;
  font-family: inherit;
  
}

.form__input2 {
  display: block;
  background-color: transparent;
  border: 0.125rem solid #f0f0f0;
  padding: 0.75rem 1rem;
  color: white;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  border-radius: 10000px;
  font-size: 0.5rem;
  width: 100%;
  font-size: 1rem;
  font-family: inherit;
  margin-top: 1.2rem;
}
 .optionS{
  background-color: #111111;
 }


.form__input:focus {
  outline: none;
}
.form__input::placeholder {
  color: transparent;
  text-shadow: none;
}

.form__label {
  position: absolute;
  top: 0.125rem;
  left: 0;
  font-size: 1rem;
  margin: 0.75rem 1rem;
  color: #f0f0f0;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  transform-origin: 0% 50%;
  transform: scale(1) translate(0, 0);
  will-change: transform;
  transition: transform 0.25s ease-in-out;
}
.form__input:focus ~ .form__label,
.form__input[placeholder]:not(:placeholder-shown) ~ .form__label {
  transform: scale(0.75) translate(0.4rem, 2.7rem);
}

.form__radio-item-radio {
  display: inline-block;
  position: relative;
  top: -0.125rem;
}

.form__radio-item-label {
  display: inline-block;
  padding-left: 0.25rem;
  color: #f0f0f0;
  font-size: 1rem;
  padding-top: 0.1rem;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
 
}

.form__radio-item--white .form__radio-item-radio {
  display: none;
}
.form__radio-item--white .form__radio-item-label::before {
  content: "";
  position: relative;
  top: 0.25em;
  display: inline-block;
  width: 0.9em;
  height: 0.9em;
  border: 0.175em solid white;
  border-radius: 100000px;
  margin-right: 0.5em;
  background-image: radial-gradient(
    circle at center center,
    white 49%,
    transparent 50%
  );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 0 0;
  transition: background-size 0.2s ease-in-out;
}
.form__radio-item--white
  .form__radio-item-radio:checked
  ~ .form__radio-item-label::before {
  background-size: 100% 100%;
}

.form_message {
  width: 100%;
  display: flex;
  justify-content: center;
}
.form_message-message {
  margin-top: 0.5rem;
  width: 100%;
  height: 7rem;
  background-color: transparent;
  border: 0.125rem solid #f0f0f0;
  padding: 0.75rem 1rem;
  color: #f0f0f0;
  text-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  font-size: 1rem;
  font-family: inherit;
}

.form_message-message:focus {
  outline: none;
}
.form_message-message::placeholder {
  text-shadow: none;
}

.offers {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  min-height: 5rem;
}

.offers__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: -0.66rem;
  margin-right: -0.66rem;
  margin-top: -1.32rem;
}

.offers__card-container {
  width: calc(33.3333333333% - 1.321rem);
  margin: 1.32rem 0.66rem 0 0.66rem;
}
@media (max-width: 62em) {
  .offers__card-container {
    width: calc(50% - 1.321rem);
  }
}
@media (max-width: 48em) {
  .offers__card-container {
    width: calc(100% - 1.321rem);
  }
}

.offers__card {
  box-shadow: 0.5rem 0.5rem 2rem rgba(0, 0, 0, 0.5);
  position: relative;
}

.offers__card-primary {
  padding: 0 0 2rem 0;
  background-color: #111111;
  border-left: 0.0625rem solid rgba(0, 0, 0, 0.15);
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.15);
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.15);
}

.offers__card-primary::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -0.0625rem;
  right: -0.0625rem;
  height: 0.75rem;
  background-image: linear-gradient(
    45deg,
    hsl(45deg 100% 33%) 0%,
    hsl(44deg 79% 38%) 18%,
    hsl(44deg 68% 43%) 26%,
    hsl(45deg 60% 47%) 33%,
    hsl(45deg 69% 49%) 39%,
    hsl(44deg 82% 51%) 44%,
    hsl(45deg 100% 50%) 50%,
    hsl(44deg 100% 56%) 56%,
    hsl(44deg 100% 60%) 61%,
    hsl(45deg 100% 63%) 67%,
    hsl(45deg 100% 66%) 74%,
    hsl(45deg 100% 69%) 82%,
    hsl(45deg 100% 73%) 100%
  );
  /*border-left: 0.0625rem solid gold;
    border-right: 0.0625rem solid gold;*/
}

.offers__image-container {
  position: relative;
  background-image: linear-gradient(
    45deg,
    hsl(45deg 100% 33%) 0%,
    hsl(44deg 79% 38%) 18%,
    hsl(44deg 68% 43%) 26%,
    hsl(45deg 60% 47%) 33%,
    hsl(45deg 69% 49%) 39%,
    hsl(44deg 82% 51%) 44%,
    hsl(45deg 100% 50%) 50%,
    hsl(44deg 100% 56%) 56%,
    hsl(44deg 100% 60%) 61%,
    hsl(45deg 100% 63%) 67%,
    hsl(45deg 100% 66%) 74%,
    hsl(45deg 100% 69%) 82%,
    hsl(45deg 100% 73%) 100%
  );
  margin-top: -0.0625rem;
  margin-right: -0.0625rem;
  margin-left: -0.0625rem;
}

.offers__image-container::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.15);
  border-right: 0.0625rem solid rgba(0, 0, 0, 0.15);
  border-left: 0.0625rem solid rgba(0, 0, 0, 0.15);
}

.offers__image-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4rem;
  background-image: linear-gradient(
    -5.7391704773deg,
    #111111 49.7%,
    transparent 50.3%
  );
  background-repeat: no-repeat;
  background-position: 50% 100%;
}
@media (max-width: 48em) {
  .offers__image-container::after {
    height: 5rem;
  }
}

.offers__image {
  width: 100%;
}

.offers__content {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.offers__content-heading {
  color: whitesmoke;
  font-size: 1.75rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.offers__content-subheading {
  color: goldenrod;
  font-size: 1rem;
}

.offers__content-subheading-hover {
  color: #f0f0f0;
  font-size: 0.85rem;
  padding: 1rem;
  text-shadow: 0 0 0.01rem #f0f0f0;
}

.offers__content-info {
  margin-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  background-image: linear-gradient(
    90deg,
    transparent calc(50% - 0.03125rem),
    rgba(0, 0, 0, 0.15) calc(50% - 0.03125rem),
    rgba(0, 0, 0, 0.15) calc(50% + 0.03125rem),
    transparent calc(50% + 0.03125rem)
  );
  background-repeat: no-repeat;
  background-size: 100% 75%;
  background-position: center center;
}

.offers__content-info-level,
.offers__content-info-duration {
  flex: 1 1 50%;
  color: #111111;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.offers__content-info-duration-text {
  padding-left: 0.33rem;
}

.offers__content-price-container {
  padding-top: 0.75rem;
  font-size: 1rem;
  color: #26464d;
}

.offers__content-price {
  display: block;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 2rem;
  line-height: 0.9em;
  text-align: right;
  visibility: hidden;
}

@media (max-width: 54em) {
  .offers__content-price {
    visibility: visible;
  }
}

.offers__card-hover {
  position: absolute;
  top: -0.0625rem;
  left: -0.0625rem;
  right: -0.0625rem;
  bottom: -0.0625rem;
  z-index: 2;
  background-color: rgb(31, 31, 31);
  background-repeat: no-repeat, no-repeat;
  background-position: center top, center center;
  background-size: 100% calc(50% - 4rem), 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.offers__card:hover .offers__card-hover {
  opacity: 1;
}

.offers__card-container--flip {
  perspective: 50rem;
}
.offers__card-container--flip .offers__card {
  transition: transform 1s ease-in-out;
  transform-style: preserve-3d;
}
.offers__card-container--flip:hover .offers__card {
  transform: rotateY(180deg);
}
.offers__card-container--flip .offers__card-primary {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
}
.offers__card-container--flip .offers__card-hover {
  transform: rotateY(180deg);
  opacity: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.offers__card-container--3d-hover,
.offers__card-container--3d {
  perspective: 50rem;
}
.offers__card-container--3d-hover .offers__card,
.offers__card-container--3d .offers__card {
  transition: transform 0.5s ease-in-out;
  transform: rotateX(calc(calc(var(--mouse-y, 0.5) - 0.5) * -20deg))
    rotateY(calc(calc(var(--mouse-x, 0.5) - 0.5) * 20deg));
}
.offers__card-container--3d-hover:hover .offers__card,
.offers__card-container--3d:hover .offers__card {
  transition: transform 0.1s linear;
}

.offers__card-container--3d .offers__card-hover {
  display: none;
}

.offers__card-container--3d-hover .offers__card-primary {
  transform-style: preserve-3d;
  filter: blur(0rem);
  transition: filter 0.5s ease-in-out;
}
.offers__card-container--3d-hover .offers__card {
  transform-style: preserve-3d;
}
.offers__card-container--3d-hover .offers__card-hover {
  background-color: transparent;
  background-image: linear-gradient(
      90deg,
      transparent calc(50% - 0.03125rem),
      white calc(50% - 0.03125rem),
      white calc(50% + 0.03125rem),
      transparent calc(50% + 0.03125rem)
    ),
    linear-gradient(
      135deg,
      rgba(0, 226, 156, 0.75) 0%,
      rgba(0, 181, 125, 0.75) 100%
    );
  transform: translateZ(1rem);
}
.offers__card-container--3d-hover:hover .offers__card-primary {
  filter: blur(0.5rem);
}

.booking-modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 20000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
}
.booking-modal:target {
  display: flex;
}
.booking-modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  animation-name: booking-modal--fade-in;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  background-color: rgba(0, 0, 0, 0.85);
}
@supports (backdrop-filter: blur(4rem)) {
  .booking-modal::before {
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4rem);
  }
}

@keyframes booking-modal--fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.booking-modal__content {
  position: relative;
  width: 45rem;
  height: 33rem;
  max-width: 100vw;
  max-height: 100vh;
  overflow: auto;
  border-radius: 0.25rem;
  padding-left: calc(min(100vw, 45rem) * 0.375);
  background-image: linear-gradient(
      95.7391704773deg,
      transparent 33%,
      rgba(17, 17, 17, 0.7) 33.15%
    ),
    linear-gradient(90deg, transparent 33%, rgba(255, 255, 255, 0.5) 100%),
    url("../img/handshake.webp");
  background-color: #7f7f7f;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center center, center center, center 30%;
  background-size: auto, auto, cover;
  box-shadow: 0 0 1rem black;
  animation-name: booking-modal__content--fade-in;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}
@media (max-width: 36em) {
  .booking-modal__content {
    
    padding-left: 1rem;
    background-color: rgba(17, 17, 17, 0.7);
    background-image: none;
  }
}

@keyframes booking-modal__content--fade-in {
  0% {
    transform: translate(0, calc(-50vh - 50%));
  }
  100% {
    transform: translate(0, 0);
  }
}
.booking-modal__close {
  display: block;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1.25rem;
  height: 1.25rem;
  color: transparent;
  border-radius: 100000px;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
  background-image: linear-gradient(
      -45deg,
      transparent 44%,
      goldenrod 45%,
      goldenrod 55%,
      transparent 56%
    ),
    linear-gradient(
      45deg,
      transparent 44%,
      goldenrod 45%,
      goldenrod 55%,
      transparent 56%
    );
}
.booking-modal__close:hover {
  opacity: 0.8;
}

.booking-modal__heading {
  padding-top: 1rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.35rem;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
}

.booking-modal__submit-container {
  text-align: right;
  margin-right: 1.5rem;
  margin-top: 1rem;
}

.booking-modal__paragraph {
  font-weight: 250;
  margin-bottom: 1rem;
  text-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
  width: 80%;
}

@media only screen and (max-width: 640px) {
  .header {
    height: 40rem;
    background-size: 100vw 12vw, 100vw 100%;
  }

  .header_mod {
    height: 17rem;
  }
  .container {
    width: 100vw;
  }
  .header__heading-main {
    font-size: 3rem;
  }
  .header__heading-sub {
    font-weight: bold;
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 640px){
  .offers__content-subheading-hover{
    font-size: 0.75rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 640px) and (max-width: 768px) {
  .offers__content-subheading-hover{
    font-size: 0.75rem;
  }
  
}

