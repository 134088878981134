* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}
body p::selection{
  background-color: goldenrod;
}
body h1::selection{
  background-color: goldenrod;
}
body h2::selection{
  background-color: goldenrod;
}
body h3::selection{
  background-color: goldenrod;
}
body li::selection{
  background-color: goldenrod;
}


