.footer {
    background-color: goldenrod;
      background-image:linear-gradient(180deg, black 0%, transparent 5%), linear-gradient(
    45deg,
    hsl(45deg 100% 33%) 0%,
    hsl(45deg 100% 36%) 17%,
    hsl(45deg 100% 40%) 25%,
    hsl(45deg 100% 44%) 31%,
    hsl(45deg 100% 49%) 37%,
    hsl(44deg 100% 54%) 42%,
    hsl(45deg 100% 58%) 47%,
    hsl(45deg 100% 60%) 53%,
    hsl(46deg 100% 62%) 58%,
    hsl(46deg 95% 63%) 63%,
    hsl(46deg 81% 59%) 69%,
    hsl(45deg 70% 55%) 75%,
    hsl(45deg 61% 51%) 83%,
    hsl(45deg 60% 47%) 100%

  );



  font-size: 17px;
  padding: 30px 5px;
  color: black;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  
  }


  .main-footer{
    padding: 40px 0 ;
    display: flex;
    justify-content: space-evenly;
    
}
.main-footer ul{
    list-style: none;
}
.main-footer h1{
   
    font-size: 22px;
    line-height: 117%;
    color: #000000;
    margin-bottom: 15px;
    font-weight: 500;
}
.main-footer h2{
    color: #020202;
    font-weight: 500;
}
.main-footer ul li a{
    color: #000000cc;
    text-decoration: none;
}

footer a{
    text-decoration: none;
    color: #000000;
    
}
.logoinfo p {
    color:#000000 ;
    font-size: 17px;
    margin-top: 5px;
   
}
.logo{
    width: 125px;
    filter:drop-shadow(0px 1px 1px #232323);
    margin-bottom: 10px;
    
}
.contact-details{
    list-style: none;
    margin: 10px 0;
}
.contact-details li a{
    text-decoration: none;
    color: #000000;
   
}
.contact-details .fa{
   width: auto;
   height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}
.sociallogos{
    padding: 0px 0;
}
.sociallogos .logobox a{
    padding: 0 5px ;
        text-decoration: none;
        color: #000000;
        font-size: 22px;
}
.com ul li{
    padding: 10px 0;
    
}
.copyright{
    margin-top: 2rem;
   
}
@media only screen and (max-width: 749px){
    .main-footer{
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .info{
        padding: 20px 0;
    }

    .iframe{
        /*center vertically*/
        margin-top: -2rem;

    }

    .copyright{
        margin-top: 2rem;
    }
}
@media (max-width: 480px){
    .main-footer{
        grid-template-columns: 1fr;
    }
    .sociallogos{
        padding: 20px 0;
    }
    .com{
        padding: 20px 0;
    }
    .iframe{
        /*center vertically*/
        margin-top: -2rem;

    }

    .copyright{
        margin-top: 2rem;
    }
}
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 640px) {
    .main-footer h1{
        font-size: 17px;
    }
    .footer{
        font-size: 13px;
    }
    .iframe{
        /*center vertically*/
        margin-top: -2rem;

    }

    .copyright{
        margin-top: 2rem;
    }
  }
  
  @media only screen and (min-width: 640px) and (max-width: 768px) {
    .main-footer h1{
        font-size: 17px;
    }
    .footer{
        font-size: 13px;
    }

    .iframe{
        /*center vertically*/
        margin-top: -2rem;

    }

    .copyright{
        margin-top: 2rem;
    }
  
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1280px) {
   
  }
  
  