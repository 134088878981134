.splash {
    display: inline-block;
    width: 100vw;
    height:100vh;
    margin: auto;
    overflow: auto;
    position: fixed;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    
    
  }
  .gif {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -999;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  .image{
width: 650px;
animation: fadeIn 1.5s;

position: absolute;
  }

  @media (max-width: 768px) {
    .image{
      width: 380px;
    } 
  }