
  
  .button {
    display: inline-block;
    border: 0.125rem solid currentColor;
    border-radius: 10000rem;
    color: black;
    text-decoration: none;
    padding: 1rem 6rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: #f0f0f0;
    border-color: #f0f0f0; 
  }

  .button:hover {
    color: whitesmoke;
    background-color: #111111;
    border-color: #111111;
  }
  
  .gallery {
    background-image: url(../img/background2.webp);
    margin-top: 0px;
    padding-top: 5rem;
    padding-bottom: 3rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
  
  .gallery__container {
    max-width: 75rem;
    margin: 0px auto;
    padding: 0 2rem;
  }
  .gallery__container::after {
    display: block;
    clear: both;
    content: "";
  }
  @media (max-width: 36em) {
    .gallery__container {
      padding: 0 1rem;
    }
  }
  
  .gallery__heading {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 4rem;
    font-size: 2rem;
  }

  .gallery__heading2 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    
    padding-bottom: 4rem;
    font-size: 2rem;
  }

  .gallery__heading3 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 4rem;
  
    font-size: 2rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .gallery__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 14.2rem 14.2rem;
  }

  .gallery__list2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 14.2rem 14.2rem 13.78rem;
  }
  @media (max-width: 75em) {
    .gallery__list {
      grid-template-rows: 12.78rem 12.78rem;
    }
    .gallery__list2 {
      grid-template-rows: 12.78rem 12.78rem;
    }
  }
  @media (max-width: 62em) {
    .gallery__list {
      grid-template-rows: 11.36rem 11.36rem;
    }
    .gallery__list2 {
      grid-template-rows: 11.36rem 11.36rem;
    }
  }
  @media (max-width: 48em) {
    .gallery__list {
      grid-template-rows: 7.1rem 7.1rem;
    }
    .gallery__list2 {
      grid-template-rows: 7.1rem 7.1rem;
    }
  }
  @media (max-width: 36em) {
    .gallery__heading {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
      padding-top: 4rem;
      font-size: 1.2rem;
    }
  
    .gallery__heading2 {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
      
      padding-bottom: 4rem;
      font-size: 1.2rem;
    }
  
    .gallery__heading3 {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
      padding-top: 4rem;
    
      font-size: 1.2rem;
    }
    .gallery__list {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 14.2rem 14.2rem 14.2rem 14.2rem 14.2rem;
    }
    .gallery__list2 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 14.2rem 14.2rem 14.2rem 14.2rem 14.2rem 14.2rem;
    }
  }
  
  .gallery__list--cb-toggle {
    animation: gallery__list--cb-toggle-show 1s ease-in-out 0s 1;
  }
  .gallery__cb-toggle:not(:checked) ~ .gallery__list--cb-toggle {
    display: none;
  }
  
  @keyframes gallery__list--cb-toggle-show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .gallery__cb-toggle {
    display: none;
  }
  
  .gallery__more-button-container {
    padding-top: 3rem;
    text-align: center;
  }
  .gallery__cb-toggle:checked ~ .gallery__more-button-container {
    display: none;
  }
  
  .gallery__list-item {
    display: block;
    position: relative;
    filter: contrast(1.1) brightness(1.15) saturate(1.1);
    transform: scale(1);
    z-index: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0);
    transition: filter 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      transform 0.2s ease-in-out, z-index 0.2s linear;
  }
  .gallery__list-item::before {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    content: "";
    mix-blend-mode: multiply;
  }
  .gallery__list-item:hover {
    box-shadow: 0 0 1rem black;
    transform: scale(1.1);
    z-index: 1000;
    transition: filter 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      transform 0.2s ease-in-out, z-index 0s linear;
    filter: contrast(1) brightness(1) saturate(1);
  }
  .gallery__list-item:hover::before {
    opacity: 0;
  }
  .gallery__list-item--first {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
  .gallery__list-item--last {
    grid-column-start: 4;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
  @media (max-width: 36em) {
    .gallery__list-item--last {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
  }
  
  .gallery__list-item-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .gallery__list-item-bg-image {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }