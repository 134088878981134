
.partner{
   
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment:local, local;
    color: white;
    margin-top: 3rem;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    background-image: linear-gradient(180deg, white 0%, black 20%),linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6));
    animation-name: fadeIn;
    background-attachment:local,local, fixed;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;

}



.icon{
    width: 1.5rem;
    height: 1.5rem;
    color: goldenrod;
}

.textContainer {
    display: flex;
    justify-content: start;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
    padding: 3.5rem;
}
.firstChild{
    margin-top: 0;
  text-align: center;
  padding: 1rem;
  width: 33.3%;
}

.textHeader{
    color: goldenrod;
    font-size: 1.4rem;
    
}
.textSub{
   
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1rem;
   
}


.container {
    max-width: 75rem;
    margin: 0px auto;
    padding: 0 2rem;
    text-align: center;
  }
  .container::after {
    display: block;
    clear: both;
    content: "";
  }

.text{
    display: inline-block;
    margin-top: 3rem;
    text-transform: uppercase;
   
}
.partnerh2{
    display: inline-block;
    margin-top: 5rem;
    text-transform: uppercase;
   
    
}

.text2{
   
    margin-top: 3rem;
    text-align: center;
    text-transform: uppercase;
}
.text h1{
 
    color: goldenrod;
    margin-bottom: 0.5rem;
    font-size: 4rem;
    margin-top: 5rem;
   
   
}
.text2 h1{
    color: goldenrod;
    margin-bottom: 0.5rem;
    font-size: 4rem;
    
    
}


.imageContainer{
   display: flex;
    align-items: flex-start;
    justify-content:center; 
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
    padding: 3.5rem;
  position: relative;
    filter: drop-shadow(0 0 0.1rem white);
}


.image1{
    margin-right: 6rem;
  width: 10rem;
  animation-name: fadeIn2;
    
}
.image2{
 

    margin-right: 6rem;
    width: 4rem;
   
}
.image3{
    margin-right: 6rem;

    width: 4rem;
   
   
  
}
.image4{
    margin-right: 6rem;
    width: 10rem;
   
}
.image5{
    margin-right: 6rem;
    width: 10rem;
    
}
.image6{
   margin-right: 6rem;
    width: 10rem;
}
.image7{
   margin-right: 6rem;
    width: 8rem;
}
.image8{
    margin-right: 6rem;
    width: 8rem;
}

.image9 {
    margin-right: 6rem;
    width: 8rem;}

.image10 {
   
   margin-right: 6rem;
    width: 8rem;
}

.image11 {
    margin-right: 6rem;
    width: 4rem;
   
}
.image12 {
    margin-right: 6rem;
    width: 7rem;
    
}


@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 640px) {
    .partner{
        margin-top: -2rem;
        padding-bottom: 2rem;
        background-attachment:local,local, local;
    }
    .container {
        padding: 0 1rem;
        width: 100vw;
      }

      .text h1{
 
        color: goldenrod;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        margin-top: 5rem;
       
    }
    .text2 h1{
        color: goldenrod;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
     
        
    }
    
    .text h2{
      
        font-size: 1.1rem;
    }
    .text2 h2{
    
        font-size: 1.1rem;
    }


      .partnerh2{
        display: inline-block;
        margin-top: 3rem;
        text-transform: uppercase;
       
        font-size: 1.1rem;
        
    }

.imageContainer{
    display: flex;
    align-items: flex-start;
    justify-content:center; 
    flex-direction: row;
    width: 100%;
    margin-top: 0.5rem;
    padding: 1.5rem;
  position: relative;
    filter: drop-shadow(0 0 0.1rem white);
}
    

      .image1{
       margin-right: 0.5rem;
        flex: 0 1 1rem;
      width: 4rem;
    }
    .image2{
     
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 1.5rem;
    }
    .image3{
        margin-right: 0.5rem;
        
        flex: 0 1 1rem;
        width:1.5rem;
       
      
    }
    .image4{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 3rem;
    }
    .image5{
        margin-right: 0.5rem;
    
        flex: 0 1 1rem;
        width: 3.5rem;
    }
    .image6{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 3.5rem;
    }

    .image7{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 2.3rem;
    }
    .image8{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 2rem;
    }
    .image9{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 2.5rem;
    }
    .image10{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 2.5rem;
    }
    .image11{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 2rem;
    }
    .image12{
        margin-right: 0.5rem;
        flex: 0 1 1rem;
        width: 3rem;
    }

    .textContainer {
        display: flex;
        justify-content: start;
        flex-direction: column;
        width: 100%;
        margin-top: 1rem;
        padding: 0.5rem;
    }

    .firstChild{
        margin-top: 2rem;
      text-align: center;
      padding: 0.5rem;
      width: 100%;
    }
    .textHeader{
        color: goldenrod;
        font-size: 1.1rem;
        
    }
    .textSub{
       
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.8rem;
       
    }

  }


  @media only screen and (min-width: 640px) and (max-width: 768px) {
    .partner{
        margin-top: -4rem;
        background-attachment:local,local, local;
    }
    .container {
        padding: 0 1rem;
        width: 100vw;
      }

      .text h1{
 
        color: goldenrod;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        margin-top: 5rem;
      
       
    }
    .text2 h1{
        color: goldenrod;
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
     
        
    }
    
    .text h2{
       
        font-size: 1.1rem;
    }
    .text2 h2{
      
        font-size: 1.1rem;
    }


      .partnerh2{
        display: inline-block;
        margin-top: 3rem;
        text-transform: uppercase;
      
        font-size: 1.1rem;
        
    }
    .imageContainer{
        display: flex;
        align-items: flex-start;
        justify-content:center; 
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        padding: 1.5rem;
      position: relative;
        filter: drop-shadow(0 0 0.1rem white);
    }
        
    
          .image1{
           margin-right: 1.5rem;
            flex: 0 1 1rem;
          width: 6rem;
        }
        .image2{
         
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 2rem;
        }
        .image3{
            margin-right: 1.5rem;
            
            flex: 0 1 1rem;
            width: 2rem;
           
          
        }
        .image4{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 5rem;
        }
        .image5{
            margin-right: 1.5rem;
        
            flex: 0 1 1rem;
            width: 5.5rem;
        }
        .image6{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 5.5rem;
        }
    
        .image7{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 4.3rem;
        }
        .image8{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 3rem;
        }
        .image9{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 3.5rem;
        }
        .image10{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 3.5rem;
        }
        .image11{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 2.5rem;
        }
        .image12{
            margin-right: 1.5rem;
            flex: 0 1 1rem;
            width: 5rem;
        }
}
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .partner{
        margin-top: -4rem;
        background-attachment:local,local, local;
    }
    .container {
        padding: 0 1rem;
        width: 100vw;
      }

      .text h1{
 
        color: goldenrod;
        margin-bottom: 0.5rem;
        font-size: 1.8rem;
        margin-top: 1rem;
    
       
    }
    .text2 h1{
        color: goldenrod;
        margin-bottom: 0.5rem;
        font-size: 1.8rem;
       
        
    }
    
    .text h2{
  
        font-size: 1.4rem;
    }
    .text2 h2{
        
        font-size: 1.4rem;
    }


      .partnerh2{
        display: inline-block;
        margin-top: 3rem;
        text-transform: uppercase;
        font-size: 1.1rem;
        
    }

    .imageContainer{
        display: flex;
        align-items: flex-start;
        justify-content:center; 
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        padding: 1.5rem;
      position: relative;
        filter: drop-shadow(0 0 0.1rem white);
    }
        
    
          .image1{
           margin-right: 2.0rem;
            flex: 0 1 1rem;
          width: 8rem;
        }
        .image2{
         
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 3rem;
        }
        .image3{
            margin-right: 2.0rem;
            
            flex: 0 1 1rem;
            width: 3rem;
           
          
        }
        .image4{
            margin-right: 2.0rem;
            flex: 0 1 1rem;
            width: 6rem;
        }
        .image5{
            margin-right: 2.0rem;
        
            flex: 0 1 1rem;
            width: 6.5rem;
        }
        .image6{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 6.5rem;
        }
    
        .image7{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 5.3rem;
        }
        .image8{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 4rem;
        }
        .image9{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 4.5rem;
        }
        .image10{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 4.5rem;
        }
        .image11{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 3rem;
        }
        .image12{
            margin-right: 2rem;
            flex: 0 1 1rem;
            width: 6rem;
        }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1380px) {

    .imageContainer{
        display: flex;
        align-items: flex-start;
        justify-content:center; 
        flex-direction: row;
        width: 100%;
        margin-top: 0.5rem;
        padding: 1.5rem;
      position: relative;
        filter: drop-shadow(0 0 0.1rem white);
    }
        
    
          .image1{
           margin-right: 3.0rem;
            flex: 0 1 1rem;
          width: 9rem;
        }
        .image2{
         
            margin-right: 3rem;
            flex: 0 1 1rem;
          
        }
        .image3{
            margin-right: 3.0rem;
            
            flex: 0 1 1rem;
           
           
          
        }
        .image4{
            margin-right: 3.0rem;
            flex: 0 1 1rem;
          width: 8rem;
        }
        .image5{
            margin-right: 3.0rem;
            width: 8rem;
            flex: 0 1 1rem;
            
        }
        .image6{
            margin-right: 3rem;
            flex: 0 1 1rem;
            width: 8rem;
        }
    
        .image7{
            margin-right: 3rem;
            flex: 0 1 1rem;
           
        }
        .image8{
            margin-right: 3rem;
            flex: 0 1 1rem;
          
        }
        .image9{
            margin-right: 3rem;
            flex: 0 1 1rem;
            
        }
        .image10{
            margin-right: 3rem;
            flex: 0 1 1rem;
            
        }
        .image11{
            margin-right: 3rem;
            flex: 0 1 1rem;
           
        }
        .image12{
            margin-right: 3rem;
            flex: 0 1 1rem;
            
        }
  }
  






/*
.about__img--slideshow-6{
    animation-name: about__img--slideshow-6;
    animation-timing-function: ease-in-out;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}


@keyframes about__img--slideshow-6{
    0%{opacity: 1;}
    1%{opacity: 1;}
    12%{opacity: 0;}
    100%{opacity: 0;}
}

.about__img--slideshow-5{
    animation-name: about__img--slideshow-5;
    animation-timing-function: ease-in-out;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

    @keyframes about__img--slideshow-5{
        0%{opacity: 0;}
        15%{opacity: 0;}
        16%{opacity: 1;}
        17%{opacity: 1;}
        28%{opacity: 0;}
        100%{opacity: 0;}
    }

    .about__img--slideshow-4{
        animation-name: about__img--slideshow-4;
        animation-timing-function: ease-in-out;
        animation-duration: 10s;
        animation-iteration-count: infinite;
        width: 230px;
    }
    
        @keyframes about__img--slideshow-4{
          0%{opacity: 0;}
          31%{opacity: 0;}
          32%{opacity: 1;}
          33%{opacity: 1;}
          44%{opacity: 0;}
          100%{opacity: 0;}
        }

        .about__img--slideshow-3{
            animation-name: about__img--slideshow-3;
            animation-timing-function: ease-in-out;
            animation-duration: 10s;
            animation-iteration-count: infinite;
            width: 100px;
        }
        
            @keyframes about__img--slideshow-3{
              0%{opacity: 0;}
              47%{opacity: 0;}
              48%{opacity: 1;}
              49%{opacity: 1;}
              58%{opacity: 0;}
              100%{opacity: 0;}
            }
            .about__img--slideshow-2{
                animation-name: about__img--slideshow-2;
                animation-timing-function: ease-in-out;
                animation-duration: 10s;
                animation-iteration-count: infinite;
                width: 100px;
            }
            
                @keyframes about__img--slideshow-2{
                  0%{opacity: 0;}
                
                  61%{opacity: 0;}
                  62%{opacity: 1;}
                  63%{opacity: 1;}
                  74%{opacity: 0;}
                  100%{opacity: 0;}
                }

                .about__img--slideshow-1{
                    animation-name: about__img--slideshow-1;
                    animation-timing-function: ease-in-out;
                    animation-duration: 10s;
                    animation-iteration-count: infinite;
                    width: 230px;
                }
                
                    @keyframes about__img--slideshow-1{
                      0%{opacity: 0;}
                      78%{opacity: 0}
                      79%{opacity: 1;}
                      80%{opacity: 1;}
                      91%{opacity: 1;}
                      100%{opacity: 0;}
                    }

                */