
  .header {
    display: block;
background-image: url("../img/background2.webp");
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    background-repeat:no-repeat;
    background-size: cover;
    background-position:  center bottom;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
  .flex{
    clear: both;
    display: flex;
   align-items: center;
   justify-content: center;
  }
  .cardcontainer{
   clear: both;
   display: flex;
   align-items: center;
   justify-content: center;
  }
  .containerText{
    color: white;
    clear: both;
    width: 80%;
    margin-top: 4rem;
    text-align: center;
   
  

    padding-top: 4rem;
    padding-left: 2rem;
    line-height: 1.5rem;
  }
  .containerText h1{
    color:goldenrod;
    margin-bottom: 1rem;
    text-transform: uppercase;
    
  }

  .containerText p{

    margin-bottom: 1rem;
   
    font-size: 1.3rem;
 
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
 
 