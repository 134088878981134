.container {
  max-width: 75rem;
  margin: 0px auto;
  padding: 0 2rem;
  margin-bottom: 5rem;
}
.container::after {
  display: block;
  clear: both;
  content: "";
}

.motivation {
  margin-top: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.motivation_column-text {
  float: left;
  width: 30%;
  padding-right: 1rem;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  
 
}

.motivation_text-heading {
  font-weight: 500;
  margin-top: 4rem;
  color: goldenrod;
  font-size: 2rem;
}

.motivation_text-heading::before {
  content: "";
  width: 3rem;
  height: 0.25rem;
  background-color: black;
  display: block;
  margin-bottom: 0.5rem;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
.motivation-text {
  margin-top: 1rem;
  font-size: 1.1rem;
  line-height: 1.6rem;
}

.motivation_column-img {
  float: left;
  width: 66.66%;
  position: relative;
  height: clamp(10rem, calc(35vw + 10rem), 36rem);
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
.motivation_image {
  width: 50%;
  position: absolute;
  box-shadow: 0 0 1rem black;
  box-sizing: content-box;
  filter: grayscale(50%);
  transition: filter ease-in-out 0.25s;
  transform: scale(1);
  transition: transform ease-in-out 0.25s;
}
.motivation_image-first {
  width: 42%;
  top: 5rem;
  left: 40%;
  z-index: 3;
}
.motivation_image-second {
  left: 25%;
  width: 75%;
  top: 0;
  z-index: 1;
}
.motivation_image-third {
  left: 20%;
  z-index: 2;
  width: 34%;
  top: 10rem;
}
.motivation_image:hover {
  border-color: goldenrod;
  border-style: solid;
  border-width: 0.33rem;
  padding: 0.33rem;
}

.motivation_image-first:hover {
  left: calc(40% - 0.66rem);
  top: calc(5rem - 0.66rem);
  z-index: 10;
  filter: grayscale(0%);
  transform: scale(1.05);
}

.motivation_image-second:hover {
  left: calc(25% - 0.66rem);
  top: calc(0rem - 0.66rem);
  z-index: 10;
  filter: grayscale(0%);
  transform: scale(1.05);
}

.motivation_image-third:hover {
  left: calc(20% - 0.66rem);
  top: calc(10rem - 0.66rem);
  z-index: 10;
  filter: grayscale(0%);
  transform: scale(1.05);
}

.containerImage  {
  max-width: 75rem;
  margin: 0px auto;
  padding: 0 2rem;
  margin-bottom: 5rem;
  margin-top: 5rem;
  text-align: center;
  

}
a[href$=".pdf"]{
  text-decoration: none;
}



.banner{
/*banner for advertisement*/
  background-image: linear-gradient(
    45deg,
    hsl(45deg 100% 33%) 0%,
    hsl(45deg 100% 36%) 17%,
    hsl(45deg 100% 40%) 25%,
    hsl(45deg 100% 44%) 31%,
    hsl(45deg 100% 49%) 37%,
    hsl(44deg 100% 54%) 42%,
    hsl(45deg 100% 58%) 47%,
    hsl(45deg 100% 60%) 53%,
    hsl(46deg 100% 62%) 58%,
    hsl(46deg 95% 63%) 63%,
    hsl(46deg 81% 59%) 69%,
    hsl(45deg 70% 55%) 75%,
    hsl(45deg 61% 51%) 83%,
    hsl(45deg 60% 47%) 100%
  );
  padding: 0.5rem;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  font-weight: 500;
  margin-top: 5rem;
  margin-bottom: 5rem;
  border-radius: 0.5rem;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  box-shadow: 0 0 1rem black;
  font-size: 2rem;
  
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 640px) {
  .container {
    padding: 0 1rem;
    width: 100vw;
  }
 
  .banner{
    font-size: 1.2rem;
  }
  .motivation_text-heading {
    font-weight: 500;
    margin-top: 2rem;
    color: goldenrod;
    font-size: 1.5rem;
  }
  .motivation-text {
    margin-top: 1rem;
    font-size: 0.75rem;
    line-height: 1.1rem;
  }
  .motivation_text-heading::before {
    content: "";
    width: 2rem;
    height: 0.15rem;
    background-color: black;
    display: block;
    margin-bottom: 0.5rem;
  }
  .motivation_column-text {
    float: left;
    width: 100%;
    padding-left: 1rem;
    
   
  }

  .motivation_column-img {
    float: left;
    width: 80%;
    position: relative;
    height: clamp(10rem, calc(35vw + 10rem), 36rem);
    margin-top: 2.5rem;
  }

  .motivation_image-third {
    left: 20%;
    z-index: 2;
    width: 34%;
    top: 4rem;
  }
  .motivation_image-first {
    width: 42%;
    top: 2rem;
    left: 40%;
    z-index: 3;
  }

  .motivation_image-first:hover {
    left: calc(40% - 0.66rem);
    top: calc(2rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
  
  .motivation_image-second:hover {
    left: calc(25% - 0.66rem);
    top: calc(0rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
  
  .motivation_image-third:hover {
    left: calc(20% - 0.66rem);
    top: calc(4rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 640px) and (max-width: 768px) {
  .container {
    padding: 0 1rem;
    width: 100vw;
  }
  .banner{
    font-size: 1.5rem;
  }
  .motivation_text-heading {
    font-weight: 500;
    margin-top: 2rem;
    color: goldenrod;
    text-shadow: 0 0 0.4px goldenrod;
    font-size: 1.6rem;
  }
  .motivation-text {
    margin-top: 1rem;
    font-size: 0.95rem;
    line-height: 1.3rem;
  }
  .motivation_text-heading::before {
    content: "";
    width: 2rem;
    height: 0.15rem;
    background-color: black;
    display: block;
    margin-bottom: 0.5rem;
  }
  .motivation_column-text {
    float: left;
    width: 100%;
    padding-left: 1rem;
    
   
  }

  .motivation_column-img {
    float: left;
    width: 80%;
    position: relative;
    height: clamp(10rem, calc(35vw + 10rem), 36rem);
    margin-top: 2.5rem;
  }

  .motivation_image-third {
    left: 20%;
    z-index: 2;
    width: 34%;
    top: 4rem;
  }
  .motivation_image-first {
    width: 42%;
    top: 2rem;
    left: 40%;
    z-index: 3;
  }

  .motivation_image-first:hover {
    left: calc(40% - 0.66rem);
    top: calc(2rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
  
  .motivation_image-second:hover {
    left: calc(25% - 0.66rem);
    top: calc(0rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
  
  .motivation_image-third:hover {
    left: calc(20% - 0.66rem);
    top: calc(4rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding: 0 1rem;
    width: 100vw;
  }
  .motivation_text-heading {
    font-weight: 500;
    margin-top: 2rem;
    color: goldenrod;
  
    font-size: 1.6rem;
  }
  .motivation-text {
    margin-top: 1rem;
    font-size: 1.15rem;
    line-height: 1.5rem;
  }
  .motivation_text-heading::before {
    content: "";
    width: 2rem;
    height: 0.15rem;
    background-color: black;
    display: block;
    margin-bottom: 0.5rem;
  }
  .motivation_column-text {
    float: left;
    width: 100%;
    padding-left: 1rem;
    
   
  }

  .motivation_column-img {
    float: left;
    width: 80%;
    position: relative;
    height: clamp(10rem, calc(35vw + 10rem), 36rem);
    margin-top: 2.5rem;
  }

  .motivation_image-third {
    left: 20%;
    z-index: 2;
    width: 34%;
    top: 4rem;
  }
  .motivation_image-first {
    width: 42%;
    top: 2rem;
    left: 40%;
    z-index: 3;
  }

  .motivation_image-first:hover {
    left: calc(40% - 0.66rem);
    top: calc(2rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
  
  .motivation_image-second:hover {
    left: calc(25% - 0.66rem);
    top: calc(0rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
  
  .motivation_image-third:hover {
    left: calc(20% - 0.66rem);
    top: calc(4rem - 0.66rem);
    z-index: 10;
    filter: grayscale(0%);
    transform: scale(1.05);
  }
}



