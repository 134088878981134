.header {
    display: block;

      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    background-repeat:no-repeat;
    background-size: cover;
    background-position:  center bottom;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

::-moz-selection {
    color: white;
    background-color: rgba(38, 70, 77, 0.8);
  }
  
  ::selection {
    color: white;
    background-color: rgba(38, 70, 77, 0.8);
  }
  
  .container {
    
    max-width: 75rem;
    margin: 0px auto;
    padding: 0 2rem;
    
  }

  .container2{
    background-image: url(../img/background2.webp);
    margin-top: 0px;

   
  }
  .fa {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: goldenrod;
  }
 
  .container::after {
    display: block;
    clear: both;
    content: "";
  }
  @media (max-width: 36em) {
    .container {
      padding: 0 1rem;
    }
  }
  
  
  
  .about {
   
    padding-top: 7rem;
    padding-bottom: 3rem;

    
    

  }
  
  .about__slideshow {
    position: relative;
  }
  
  .about__img {
    width: 32rem;
    height: 32rem;
    object-fit: cover;
    float: left;
    border-radius: 1000000px;
    /* clip-path: circle(50% at 50% 50%); */
    shape-outside: circle(50% at 50% 50%);
    margin-right: 2rem;
      object-position: center;
  }
  @media (max-width: 75em) {
    .about__img {
      width: 28.8rem;
      height: 28.8rem;
    }
  }
  @media (max-width: 62em) {
    .about__img {
      width: 25.6rem;
      height: 25.6rem;
    }
  }
  @media (max-width: 48em) {
    .about__img {
      width: 12.8rem;
      height: 12.8rem;
    }
  }
  @media (max-width: 36em) {
    .about__img {
      width: 9.6rem;
      height: 9.6rem;
    }
  }
  
  
  .about__heading {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 2rem;
    color: goldenrod;
  }
  
  .about__heading-new {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    font-size: 1rem;
    color: goldenrod;
  }
  
  .about__text {
    padding-top: 2.5rem;
    color: white;
  }
  .about__text::before {
    content: "";
    display: inline-block;
    height: 0.25rem;
    width: 4rem;
    margin-left: 1rem;
    background-color: goldenrod;
  }
  @media (max-width: 75em) {
    .about__text {
      padding-top: 0.5rem;
    }
  }
  
  .about__paragraph {
    font-weight: 300;
    margin-bottom: 1rem;
  }
  
  .about__paragraph-link {
      font-weight: 300;
    margin-bottom: 1rem;
      text-decoration: none;
      color: white;
  }
  
  .about__paragraph-link:hover {
     font-weight: 500;
  }
  
  .about__quote {
    font-style: italic;
  }
  .about__quote::before {
    content: "„";
  }
  .about__quote::after {
    content: "“";
  }
  
  .fifty {
      width: 100%;
  }


    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 640px) {
        .about__heading{
            font-size: 17px;
        }
        .about__heading-new{
            font-size: 13px;
        }
        .about__paragraph{
            font-size: 13px;
        }
      }
      
      @media only screen and (min-width: 640px) and (max-width: 768px) {
        .about__heading{
            font-size: 17px;
        }
        .about__heading-new{
            font-size: 13px;
        }
        .about__paragraph{
            font-size: 13px;
        }
      
      }
      
      @media only screen and (min-width: 1024px) and (max-width: 1280px) {
       
      }
  