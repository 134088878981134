.header {
  height: 40rem;
  min-height: calc(17rem + 10vw);
  width: 100vw;
  background-repeat:  no-repeat;
  background-position:  center bottom;
  background-size: 100vw 10vw, 100vw 100%;
  position: relative;
  overflow: hidden;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  /*make a video background*/

}
.story__video {
  position: absolute;
  z-index: -1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  filter: brightness(0.6);
  
  
}

.container {
  width: 75rem;
  margin: 0px auto;
}

.header__heading-container {
  padding-top: 3rem;
  clear: both;
  color: white;
  text-shadow: 0 0 0.45rem white;
}
.header__heading-main {
  font-weight: 700;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 92%;
  font-size: 7rem;
  text-align: center;
  text-transform: uppercase;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  margin-top: 5rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header__heading-sub {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  font-size: 1.45rem;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  margin-top: 1rem;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 640px) {
  .header {
    height: 40rem;
  }

  .story__video {
    position: absolute;
    z-index: -1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    filter: brightness(0.6);
    /*take full heght video*/
    transform: scale(8.4);
    
  }
  

  .header_mod {
    height: 17rem;
  }
  .container {
    width: 100vw;
  }
  .header__heading-main {
    font-size: 3rem;
  }
  .header__heading-sub {
    font-weight: bold;
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-top: 1rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 640px) and (max-width: 768px) {
  .header {
    height: 40rem;
  }

  .story__video {
    position: absolute;
    z-index: -1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    filter: brightness(0.6);
    /*take full heght video*/
    transform: scale(10.4);
    
  }

  .header_mod {
    height: 16rem;
  }
  .container {
    width: 100vw;
  }
  .header__heading-main {
    font-size: 3.5rem;
  }
  .header__heading-sub {
    font-weight: bold;
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header {
    height: 40rem;
  }

  .story__video {
    position: absolute;
    z-index: -1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    filter: brightness(0.6);
    /*take full heght video*/
    transform: scale(3.4);
    
  }

  .header_mod {
    height: 18rem;
  }
  .container {
    width: 100vw;
  }
  .header__heading-main {
    font-size: 4.5rem;
  }
  .header__heading-sub {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .header {
    height: 40rem;
  }
  .story__video {
    position: absolute;
    z-index: -1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    filter: brightness(0.6);
    /*take full heght video*/
    transform: scale(1.4);
    
  }

  .header_mod {
    height: 18rem;
  }
  .container {
    width: 100vw;
  }
  .header__heading-main {
    font-size: 6.5rem;
  }
  .header__heading-sub {
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    margin-top: 1rem;
  }
}
