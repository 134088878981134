.icon {
  fill: goldenrod;
  height: 1em;
  vertical-align: bottom;
  position: relative;
  top: -0.075em;
  display: none;

}

.navigation{
 position: fixed;
 backdrop-filter: blur(100px);
 z-index: 10000;
 width: 100%;
 
}

.navigation__list-item {
  list-style-type: none;
  display: block;
  float: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;

  
}

.navigation__list-item2 {
  list-style-type: none;
  display: block;
  float: left;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  
}

.navigation__list-item_icon {
  list-style-type: none;
  display: block;
  float: left;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}
.navigation__list-item-link {

  color: goldenrod;
  text-decoration: none;
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: block;
 
  font-weight: 700;
}
.navigation__list-item-link2 {

  color: goldenrod;
  text-decoration: none;
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: block;

  font-size: 1rem;
}

.navigation__list-item2 select {

  background-color: transparent;
  border-color: goldenrod;
  border-radius: 10px;
  font-weight: 700;
  border-width: 3px;

}

.navigation__list-item-link_icon {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 0.5rem 0.25rem 0.25rem 0.25rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 2rem;
  display: block;
  text-shadow: 0 0 0.5rem white;
}

.navigation__list-item-link:hover {
  border-bottom: 0.1rem solid rgb(255, 255, 255);
}

.navigation__list-item--menu .navigation__list-item-link_icon {
  color: white;
  border-bottom: none;
}

.navigation__list-item-img {
  width: 7rem;
  min-width: calc(7rem + 1vw);
  margin-top: 1.8rem;
  margin-right: 2rem;
  margin-left: 2rem;
  padding-bottom: 0.9rem;

  display: block;
}

.navigation__list-item--menu {
  font-size: 3rem;
  float: right;
}
.navigation__list-item--menu .navigation__list-item-link_icon {
  margin-top: 0.8rem;
}
.navactiv{
  border-bottom: 1px solid white;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 640px) {
  .navigation__list-item-link {
    display: none;
  }
  .navigation__list-item-img{
    margin-left: 1rem;
  }
  .navigation__list-item-link_icon{
    margin-right: 2rem;
    
  }
  .navigation{
    width: 100vw;
  }
  .icon{
    display: block;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 640px) and (max-width: 768px) {
  .navigation__list-item-link {
    display: none;
  }
  .navigation__list-item-img{
    margin-left: 1rem;
  }
  .navigation__list-item-link_icon{
    margin-right: 2rem;
    
  }
  .navigation{
    width: 100vw;
  }
  .icon{
    display: block;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:980px){
  .navigation__list-item-link {
    display: none;
  }
  .icon{
    display: block;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1024px) and (max-width:1280){
 
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.flag-icon {
  width: 24px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
}