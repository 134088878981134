.sidebar-container {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
   background-color: black;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .opacity-on {
    opacity: 1;
    top: 0;
  }
  .opacity-off {
    opacity: 0;
    top: -200%;
  }
  .icon {
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 2rem;
    right: 2.5rem;
  }
  

  .sideactiv:active{
    transform: scale(2.0);
    
  }

  .sidebar-menu {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 80px);
    margin-bottom: 4rem;
    margin-left: 2rem;
  
  }

  .sidebar-links {
    margin-top: auto ;
    font-size: large;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: rgb(250, 250, 250);
    font-size: 1.1rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    margin-top: 6rem;
  
    
  }
  .sideactiv{
    color:goldenrod;
    font-size: 1.2rem;
    
  }

  .icon_react{
    
    margin-right: 1rem;
  
 
    
  }

  .svg_react{
   
    padding-top: 5rem;
  }
  
  @media screen and (max-width: 480px) {
    .sidebar-menu {
      grid-template-rows: repeat(7, 60px);
    }
  }
  .button-wrap {
    display: flex;
    justify-content: center;
  }
  