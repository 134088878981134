.BG{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-image: url(../img/background2.webp);
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
}
.motivation {
    margin-top: 0rem;
 
 
  }

  .container {
    color: white;
    clear: both;
    width: 80%;
    text-align: center;
   
    margin: 0 auto;
    padding-top: 8rem;
    padding-left: 2rem;
    line-height: 1.5rem;
    
  }
 

 
.buttonContainer {
    margin-top: 4rem;
    margin-bottom: 0rem;
    text-align: center;
  }
  
  .motivation__text-heading {
    color:goldenrod;
    margin-bottom: 1rem;
    text-transform: uppercase;
    
  }

  
  .motivation__text {
    color: #ffffff;
    margin-top: 0.2rem;
    font-size: 1.3rem;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  
  .tours {

    position: relative;
    padding-bottom: 10rem;
  }
  

  
  .tours__container {
    position: relative;
    z-index: 1;
    max-width: 75rem;
    margin: 0px auto;
    padding: 0 2rem;
  }
  
  .tours__container::after {
    display: block;
    clear: both;
    content: "";
  }
  
  @media (max-width: 36em) {
    .tours__container {
      padding: 0 1rem;
    }
  }
  
  .tours__heading {
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 1rem;
    font-size: 2rem;
  }
  
  .tours__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .tours__card {
    flex: 0 0 16.65%;
    position: relative;
    margin-top: 4rem;
    height: 21rem;
    overflow: hidden;
    box-shadow: 0 0 1rem transparent;
    transform: scale(1);
    transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out, z-index 0.25s linear;
    z-index: 0;
    
  }
  @media (max-width: 75em) {
    .tours__card {
      flex: 0 0 14.2rem;
    }
  }
  .tours__card:hover {
    transform: scale(1.15);
    box-shadow: 0 0 1rem black;
    transition: transform 0.25s ease-in-out, box-shadow 0.25s ease-in-out, z-index 0s linear;
    z-index: 1000;
  }
  
  .tours__card-icon-background {
    /*background-color: #00e29c;*/
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    /*box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);*/
    transform: translate(calc(-100% + 3rem), 0);
    transition: transform 0.25s ease-in-out;
  }
  .tours__card:hover .tours__card-icon-background {
    transform: translate(0, 0);
  }
  
  .tours__card-img {
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
      height: 100%;
      background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(80%);
  }
  
  .tours__card-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translate(0, 0);
    transition: transform 0.25s ease-in-out;
  }
  .tours__card:hover .tours__card-icon-container {
    transform: translate(calc(50% - 1.5rem), 0);
  }
  
  .tours__card-icon {
    width: 3rem;
    color: white;
    display: block;
    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
  }
  
  .tours__card-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    padding: 3rem 1rem 0 1rem;
    text-shadow: 0 0 0.125rem black;
    background-image: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.25) 20%);
    transform: translate(0, calc(100% - 7.25rem));
    transition: transform 0.25s ease-in-out;
  }
  .tours__card:hover .tours__card-content {
    transform: translate(0, 0);
  }
  
  .tours__card-heading {
    font-size: 1.1rem;
    margin-bottom: 0.25rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .tours__card-location {
    font-size: 0.9rem;
    margin-bottom: 0.6rem;
    display: flex;
  }
  
  .tours__card-location-icon {
    font-size: 1.25rem;
  }
  
  .tours__card-location-text {
    line-height: 1.9rem;
    padding-left: 0.15rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .tours__card-desc {
    font-size: 0.8rem;
    padding: 0.9rem 0;
    background-image: linear-gradient(90deg, goldenrod 60%, transparent 60%);
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: 100% 0.125rem;
  }
  