.container {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    height: 100%;
    
    background-color: black;
   
    color: white;
    padding-top: 8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    line-height: 2.0rem;
    padding-bottom: 5rem;
    font-size: 1.3rem;
}
.containerText{
    width: 80%;
    margin: 0 auto;
}
.container a{
    color : white;
}

.container p{
    font-size: 1.3rem;
    
    
    
}


.noIMG{
    background-color: aliceblue;
}

.header {
    font-size: 2.5rem;
    
    font-weight: 700;
    text-align: center;
    margin-bottom: 7rem;
    color: goldenrod;
}


.headline{
    font-size: 2rem;
    font-weight: 700;
    color: goldenrod;
    margin-bottom: 2rem;
    margin-top: 2rem;
    
  
}


.headline2{
    color: goldenrod;
    font-size: 1.4rem;
}
.containerimp{
   text-align: center;
}

/*make it responsive*/
@media screen and (max-width: 768px) {
   
    .containerText{
        width: 90%;
    }
    .header{
        font-size: 1.5rem;
    }
    .headline{
        font-size: 1.3rem;
    }

    .container p{
        font-size: 1.1rem; 
    }
    .link{
        font-size: 0.7rem;
    }
}