.container {
    max-width: 100%;
    margin: 0px 0px;
    padding: 0 0rem;
}


.header {
    display: block;
background-color: black;
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    background-repeat:no-repeat;
    background-size: cover;
    background-position:  center bottom;
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }
  .button {
    color: #f0f0f0;
    background-color: #1111;
    border: 0.125rem solid #f0f0f0;
  }
  .button:hover {
    color: #111111;
    background-color: #f0f0f0;
    border: 0.125rem solid #111111;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.container::after {
    display: block;
    clear: both;
    content: "";
}

@media (max-width: 36em) {
    .container {
        padding: 0 1rem;
    }
}
@media (max-width: 680em){
    .container{ max-width: 100%;
        margin: 0px 0px;
        padding: 0 0rem;

    }
}

.button {
    display: inline-block;
    border: 0.125rem solid currentColor;
    border-radius: 10000rem;
    text-decoration: none;
    padding: 1rem 6rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.5s ease-in-out ;
    
}

.button:hover {

    background: whitesmoke;
}



.button--background-blur {
    -webkit-backdrop-filter: blur(3rem);
    backdrop-filter: blur(3rem);
}

.button--white {
    color: white;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
}

.button--white:hover {
    color: #e6e6e6;
}





.story {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 3rem;
}

.story__video {
   
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-top: 3rem;
}

@supports (object-fit: cover) and (object-position: center center) {
    .story__video {
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.story__video-colorize {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
}

.story__container {
    position: relative;
    z-index: 2;
    max-width: 75rem;
    margin: 0px auto;
    padding: 0 2rem;
}

.story__container::after {
    display: block;
    clear: both;
    content: "";
}

@media (max-width: 36em) {
    .story__container {
        padding: 0 1rem;
    }
}

.story__heading {

    color: goldenrod;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: left;
    float: left;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 2rem;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
    padding-top: 11rem;
}

.story__heading2 {
    position: absolute;
    color: goldenrod;
    text-transform: uppercase;
    font-weight: bolder;
    letter-spacing: 0.1em;
    text-align: center;
    left: 0;
    right: 0;
    text-shadow: 0.2rem 0.2rem 0.125rem rgba(0, 0, 0, 1);
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 3.5rem;
   /* center it vertically */
    top: 50%;
    transform: translateY(-50%);

    
    z-index: 222;
}

.story__heading-link {
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 2rem;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

.story__heading-link:hover {
    font-weight: 700;
}

.story__desc {
    max-width: 40rem;
    margin: 0 auto;
    color: #f0f0f0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
    column-count: 1;
    column-gap: 2rem;
    column-rule: 0.125rem solid rgba(255, 255, 255, 0.5);
    text-align: justify;
    line-height: 1.6rem;
    padding-top: 3rem;
    font-size: 1.3rem;
    float: right;
}

@media (max-width: 48em) {
    .story__desc {
        column-count: 1;
        text-align: left;
    }

    .story__heading2 {
        position: absolute;
        color: goldenrod;
        text-transform: uppercase;
        font-weight: bolder;
        letter-spacing: 0.1em;
        text-align: center;
        left: 0;
        right: 0;
        text-shadow: 0.2rem 0.2rem 0.125rem rgba(0, 0, 0, 1);
        padding-top: 4rem;
        padding-bottom: 4rem;
        font-size: 1.5rem;
       /* center it vertically */
        top: 50%;
        transform: translateY(-50%);
    
        
        z-index: 222;
    }

    .story__heading {

        color: goldenrod;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-align: center;
        
        padding-top: 4rem;
        padding-bottom: 4rem;
        font-size: 2rem;
        text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
        padding-top: 11rem;
    }
    .story__video {
        height: max-content;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
      
    }
    
}

.story__book {
    text-align: center;
    padding: 4rem 0 4rem 0;
}



.gallery {
    padding-bottom: 3rem;
}

.gallery__container {
    max-width: 75rem;
    margin: 0px auto;
    padding: 0 2rem;
}

.gallery__container::after {
    display: block;
    clear: both;
    content: "";
}

@media (max-width: 36em) {
    .gallery__container {
        padding: 0 1rem;
    }

    .story__heading2 {
        position: absolute;
        color: goldenrod;
        text-transform: uppercase;
        font-weight: bolder;
        letter-spacing: 0.1em;
        text-align: center;
        left: 0;
        right: 0;
        text-shadow: 0.2rem 0.2rem 0.125rem rgba(0, 0, 0, 1);
        padding-top: 4rem;
        padding-bottom: 4rem;
        font-size: 1.5rem;
       /* center it vertically */
        top: 50%;
        transform: translateY(-50%);
    
        
        z-index: 222;
    }

    .story__heading {

        color: goldenrod;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-align: center;
        
        padding-top: 4rem;
        padding-bottom: 4rem;
        font-size: 2rem;
        text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
        padding-top: 11rem;
    }
    .story__video {
        height: max-content;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        
    }
    

}

.gallery__heading {
    color: #26464d;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 2rem;
}

.gallery__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 14.2rem 14.2rem;
}

@media (max-width: 75em) {
    .gallery__list {
        grid-template-rows: 12.78rem 12.78rem;
    }
}

@media (max-width: 62em) {
    .gallery__list {
        grid-template-rows: 11.36rem 11.36rem;
    }
    .story__heading2 {
        position: absolute;
        color: goldenrod;
        text-transform: uppercase;
        font-weight: bolder;
        letter-spacing: 0.1em;
        text-align: center;
        left: 0;
        right: 0;
        text-shadow: 0.2rem 0.2rem 0.125rem rgba(0, 0, 0, 1);
        padding-top: 4rem;
        padding-bottom: 4rem;
        font-size: 1.5rem;
       /* center it vertically */
        top: 50%;
        transform: translateY(-50%);
    
        
        z-index: 222;
    }

    .story__heading {

        color: goldenrod;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-align: center;
        
        padding-top: 4rem;
        padding-bottom: 4rem;
        font-size: 2rem;
        text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.5);
        padding-top: 11rem;
    }
    .story__video {
        height: max-content;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        
    }
    
}

@media (max-width: 48em) {
    .gallery__list {
        grid-template-rows: 7.1rem 7.1rem;
    }
}

@media (max-width: 36em) {
    .gallery__list {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 14.2rem 14.2rem 14.2rem 14.2rem 14.2rem;
    }
}

.gallery__list--cb-toggle {
    animation: gallery__list--cb-toggle-show 1s ease-in-out 0s 1;
}

.gallery__cb-toggle:not(:checked) ~ .gallery__list--cb-toggle {
    display: none;
}

@keyframes gallery__list--cb-toggle-show {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.gallery__cb-toggle {
    display: none;
}

.gallery__more-button-container {
    padding-top: 3rem;
    text-align: center;
}

.gallery__cb-toggle:checked ~ .gallery__more-button-container {
    display: none;
}

.gallery__list-item {
    display: block;
    position: relative;
    filter: contrast(1.1) brightness(1.15) saturate(1.1);
    transform: scale(1);
    z-index: 0;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0);
    transition: filter 0.2s ease-in-out, box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out, z-index 0.2s linear;
}

.gallery__list-item::before {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    background: radial-gradient(circle closest-corner, transparent 0, rgba(175, 105, 24, 0.4) 100%);
    content: "";
    mix-blend-mode: multiply;
}

.gallery__list-item:hover {
    box-shadow: 0 0 1rem black;
    transform: scale(1.1);
    z-index: 1000;
    transition: filter 0.2s ease-in-out, box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out, z-index 0s linear;
    filter: contrast(1) brightness(1) saturate(1);
}

.gallery__list-item:hover::before {
    opacity: 0;
}

.gallery__list-item--first {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 2;
}

.gallery__list-item--last {
    grid-column-start: 4;
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 2;
}

@media (max-width: 36em) {
    .gallery__list-item--last {
        grid-column-start: 1;
        grid-column-end: span 2;
    }
}

.gallery__list-item-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery__list-item-bg-image {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


